@import '@assets/theme';

.Toggle {
    $root: &;
    position: relative;
    height: 30px;

    display: flex;
    align-items: center;

    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;

    &.is-disabled {
        pointer-events: none;
        color: $color_opaque_soft_black;
    }

    &__input {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        pointer-events: none;
        opacity: 0;
        margin: 0;
    }

    &__label {
        font-size: 15px;
    }

    &__icon {
        $size: 20px;
        width: $size;
        height: $size;
        margin-right: 12px;
        position: relative;
        color: rgba($color_light_gray, 0.4);
        transition:
            color 300ms,
            background-color 300ms,
            border-color 300ms;
        background-color: #fff;
        box-sizing: border-box;

        #{$root}:hover & {
            color: $color_primary_aqua;
        }

        #{$root}.is-checked & {
            color: $color_primary_aqua;
        }

        #{$root}.is-disabled & {
            color: rgba($color_light_gray, 0.1);
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            transition: opacity 300ms;
        }

        &.is-checkbox {
            border: 2px solid currentColor;
            border-radius: 6px;
            background-color: transparent;
            flex-shrink: 0;

            #{$root}.is-checked & {
                border-color: transparent;
                background-color: currentColor;
            }

            @include checkbox-icon;

            &:before,
            &:after {
                opacity: 0;

                #{$root}.is-checked & {
                    opacity: 1;
                }
            }
        }

        &.is-radio {
            border-radius: 50%;
            background-color: transparent;
            border: 2px solid;

            &:before {
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: 50%;
                background-color: currentColor;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;

                #{$root}.is-checked & {
                    opacity: 1;
                }
            }
        }

        &.is-switch {
            $width: 34px;
            $height: 22px;
            $indent_x: 4px;
            $indent_y: 3px;
            width: $width;
            height: $height;
            background-color: currentColor;
            border-radius: $height * 0.5;

            #{$root}:not(.is-checked):hover & {
                color: rgba($color_light_gray, 0.5);
            }

            &:before {
                width: $height - 2 * $indent_y;
                height: $height - 2 * $indent_y;
                top: $indent_y;
                left: $indent_x;
                background-color: #fff;
                border-radius: 50%;
                transition: transform 300ms;

                #{$root}.is-checked & {
                    transform: translateX(#{$width - ($height - 2 * $indent_y) - 2 * $indent_x});
                }
            }
        }
    }
}
