@import '@assets/theme';

.Page {
    height: 100vh;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 45px 40px 32px 40px;
    background-color: $color_off_white;
    position: relative;
    z-index: 1;
	scrollbar-gutter: stable;

    @include breakpoint(medium) {
        padding: 24px 20px;
        max-height: calc(100vh - 66px);

        &.hidden {
            transition: all 600ms ease-in-out;
            height: 0;
            padding: 0;
        }
    }
}
