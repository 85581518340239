@import '@assets/theme';

.TagsInput {
    &__root {
        padding: 7px;
        flex-wrap: wrap;
        height: auto;
        gap: 7px;
    }

    &__input {
        display: flex;
        flex: 1 1 60px;
        padding: 0px 10px;
        height: 28px;
    }

    &__chips-container {
        display: flex;
        flex-wrap: wrap;
        padding-left: 14px;
        column-gap: 4px;
    }
}
